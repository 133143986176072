"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config = {
    title: 'vercel.json',
    type: 'now',
    description: 'Configuration for your deployments on Vercel.',
    moreInfoUrl: 'https://vercel.com/docs/configuration#introduction/configuration-reference',
    getDefaultCode: () => JSON.stringify({}, null, 2),
};
exports.default = config;
