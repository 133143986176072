"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config = {
    title: 'netlify.toml',
    type: 'netlify',
    description: 'Configuration for your deployments in netlify.',
    moreInfoUrl: 'https://www.netlify.com/docs/netlify-toml-reference/',
    getDefaultCode: () => '',
};
exports.default = config;
