"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config = {
    title: 'babel-transpiler.json',
    type: 'babelTranspiler',
    description: 'Configuration for the Babel REPL.',
    moreInfoUrl: 'https://eslint.org/docs/user-guide/configuring',
    getDefaultCode: () => '{}',
};
exports.default = config;
