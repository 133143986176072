"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    printWidth: 80,
    tabWidth: 2,
    useTabs: false,
    semi: true,
    singleQuote: false,
    trailingComma: 'none',
    bracketSpacing: true,
    jsxBracketSameLine: false,
};
