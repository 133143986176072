"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const url_generator_1 = require("../../../utils/url-generator");
const config = {
    title: 'sandbox.config.json',
    type: 'sandbox',
    description: 'Configuration specific to the current sandbox.',
    moreInfoUrl: (0, url_generator_1.docsUrl)('/learn/browser-sandboxes/configuration#sandbox-configuration'),
    getDefaultCode: () => JSON.stringify({
        infiniteLoopProtection: true,
        hardReloadOnChange: false,
        view: 'browser',
    }, null, 2),
};
exports.default = config;
